import routes, { apiActions } from "./routes"

const attributes = {
    ID: 1,
    AvailableSellQuantity: null,
    Brand: null,
    Categories: null,
    CostPrice: null,
    DateAdded: null,
    DefaultPrice: null,
    PreOrderEta: null,
    Description: null,
    Features: null,
    ImageUrl: null,
    ImageList: null,
    InventoryId: null,
    ItemSpecifics: null,
    Name: null,
    ShortName: null,
    RRP: null,
    SKU: null,
    ShortDescription: null,
    Specifications: null,
    Subtitle: null,
    WarehouseQuantity: null,
    Warranty: null,
    CarModels: null,
    ProductStyle: null,
    PackageLength: null,
    PackageWidth: null,
    PackageHeight: null,
    PackageWeight: null,
    ProductLength: null,
    ProductWidth: null,
    ProductHeight: null,
    ProductWeight: null,
    ProductWarrantyYears: null,
    Youtube: null,
    IsComboDeal: null,
    ComboDealDetails: null,
    SpecLoadRatingOnroadStatic: null,
    SpecLoadRatingOnroadDynamic: null,
    SpecLoadRatingOffroad: null,
    SpecMaterial: null,
    CompatibleProducts: null,
    active: null,
}

const definition = { attributes, routes }

export { apiActions }
export default definition