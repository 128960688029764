export default {
  tabs: [
    { label: "Title & Images" },
    { label: "Description" },
    { label: "Categories, Car Models, Specs" },
    { label: "Product Accessories" },
    { label: "Prices & Stock" },
    { label: "Shipping & Warranty" },
  ],
  fields: {
    productDetails: {
      _options: {defaultFieldWidth: 8, tab: 0},
      active: {
        type: "select",
        initialValue: "active",
        label: "Status",
        options: [
          {label: "Active", value: "1"},
          {label: "Inactive", value: "0"},
          {label: "Out of stock", value: "out_of_stock"}
        ]
      },
      placholderA: {
        type: "placeholder",
        width: 12,
      },
      SKU: {
        width: 12,
        type: "text",
        label: "SKU",
      },
      placeHolder: {
        type: "placeholder"
      },
      Name: {
        width: 12,
        type: "text",
        label: "Product Title",
        placeholder: "ie. Gold Package",
        validation: [{required: true, message: "Please enter a product name", trigger: "blur"}]
      },
      ShortName: {
        width: 12,
        type: "text",
        label: "Product Card Title (Short Name)",
        placeholder: "ie. Gold Package",
        validation: [{required: true, message: "Please enter a product short name", trigger: "blur"}]
      },
      Subtitle: {
        width: 12,
        type: "text",
        label: "Subtitle",
      },
      Brand: {
        width: 12,
        type: "text",
        label: "Brand",
      },
      ImageList: {
        label: "Product Images",
        width: 24,
        type: "canetoad-image-upload",
        options: {
          multiple: true,
        },
        value: true,
      },
      ProductCard: {
        label: "Product Card Preview",
        width: 24,
        type: "product-card",
        props(Form) {
          return {
            name: Form.formData.productDetails.Name.value,
            shortName: Form.formData.productDetails.ShortName.value,
            subtitle: Form.formData.productDetails.Subtitle.value,
            brand: Form.formData.productDetails.Brand.value,
            images: Form.formData.productDetails.ImageList.value,
          }
        },
      },

    },
    productDescription: {
      _options: {defaultFieldWidth: 8, tab: 1},
      Description: {
        width: 24,
        type: "html-editor",
        label: "Product Description",
      },
      Suggestion: {
        width: 24,
        type: "suggestion",
        options: {
          type: 'product'
        },
        props(Form) {
          return {
            inputTitle: Form.formData.productDetails.Name.value,
            inputText: Form.formData.productDescription.Description.value,
          }
        },
      },
      Features: {
        width: 24,
        type: "html-editor",
        label: "Product Features",
      },
      Youtube: {
        label: "Youtube Video Url",
        width: 24,
        type: "text",
      },
    },
    productCategories: {
      _options: {defaultFieldWidth: 8, tab: 2},
      Categories: {
        type: "entity-select",
        width: 12,
        props: {
          entity: "ShopCategory",
          filterable: true,
          clearable: true,
          multiple: true,
        },
        label: "Product Category",
        options: [],
        validation: [{required: true, message: "Please select a product category", trigger: "blur"}]
      },
      CarModels: {
        label: "Car Model(s)",
        type: "carmodel-select",
        width: 12,
        props: {
          multiple: true
        }
      },
    },
    productSpecifications: {
      _options: {defaultFieldWidth: 8, tab: 2},
      ProductStyle: {
        width: 6,
        type: "select",
        label: "Product Style",
        initialValue: "standard",
        options: [
          { label: "Standard", value: "standard" },
          { label: "Low Profile", value: "low_profile" },
        ],
      },
      placeholder: {
        type: "placeholder",
        width: 18,
      },
      SpecLoadRatingOnroadStatic: {
        width: 6,
        type: "text",
        label: "Load Rating On-road (Static)",
        props: {
          append: "kg",
        }
      },
      SpecLoadRatingOnroadDynamic: {
        width: 6,
        type: "text",
        label: "Load Rating On-road (Dynamicc)",
        props: {
          append: "kg",
        }
      },
      SpecLoadRatingOffroad: {
        width: 6,
        type: "text",
        label: "Load Rating Off-road (Recommended)",
        props: {
          append: "kg",
        }
      },
      SpecMaterial: {
        width: 6,
        type: "text",
        label: "Material(s)"
      }
    },
    productAccessories: {
      _options: {defaultFieldWidth: 8, tab: 3},
      CompatibleProducts: {
        type: "entity-select",
        width: 12,
        props: {
          entity: "CaneToadProduct",
          filterable: true,
          clearable: true,
          multiple: true,
        },
        label: "Compatible Products",
        options: [],
        validation: [{required: true, message: "Please select a product category", trigger: "blur"}]
      },
    },
    productMeta: {
      _options: {defaultFieldWidth: 8, tab: 4},
      DefaultPrice: {
        width: 6,
        type: "text",
        label: "Sell Price",
        placeholder: "100.00",
        props: {
          append: "AUD"
        },
        validation: [{required: true, message: "Please enter a product price", trigger: "blur"}]
      },
      CostPrice: {
        width: 6,
        type: "text",
        label: "Cost Price",
        placeholder: "100.00",
        props: {
          append: "AUD"
        },
        validation: [{required: true, message: "Please enter a product price", trigger: "blur"}]
      },
      RRP: {
        width: 6,
        type: "text",
        label: "RRP",
        placeholder: "100.00",
        props: {
          append: "AUD"
        },
        validation: [{required: true, message: "Please enter a product price", trigger: "blur"}]
      },
      AvailableSellQuantity: {
        width: 6,
        type: "number",
        label: "Stock at Hand",
        validation: [{required: true, message: "Please enter the stock number", trigger: "blur"}]
      },
      PreOrderEta: {
        width: 6,
        type: "date",
        label: "Pre-Order ETA",
        props: {
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd",
          placeholder: "01/01/1980",
          clearable: true,
        }
      },
      IsComboDeal: {
        type: "switch",
        label: "Is Combo Deal",
        width: 2,
        props: {
          'active-value': "1",
          'inactive-value': "0",
        }
      },
      ComboDealDetails: {
        type: "text",
        label: "Combo Deal Details",
      }
    },
    shippingAndPackaging: {
      _options: {defaultFieldWidth: 8, tab: 5},
      PackageWidth: {
        width: 6,
        type: "text",
        label: "Package Width",
        props: {
          append: "m",
        }
      },
      PackageLength: {
        width: 6,
        type: "text",
        label: "Package Length",
        props: {
          append: "m",
        }
      },
      PackageHeight: {
        width: 6,
        type: "text",
        label: "Package Height",
        props: {
          append: "m",
        }
      },
      PackageWeight: {
        width: 6,
        type: "text",
        label: "Package Weight",
        props: {
          append: "kg",
        }
      },
      ProductWidth: {
        width: 6,
        type: "text",
        label: "Product Width",
        props: {
          append: "m",
        }
      },
      ProductLength: {
        width: 6,
        type: "text",
        label: "Product Length",
        props: {
          append: "m",
        }
      },
      ProductHeight: {
        width: 6,
        type: "text",
        label: "Product Height",
        props: {
          append: "m",
        }
      },
      ProductWeight: {
        width: 6,
        type: "text",
        label: "Product Weight",
        props: {
          append: "kg",
        }
      },
      ProductWarrantyYears: {
        width: 6,
        type: "number",
        label: "Warranty",
        props: {
          append: "years",
        }
    },
    }
  }
}
